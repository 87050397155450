<template>
  <div id="SurveyPreview">
    <div class="main">
      <Spin fix v-if="loading"></Spin>
      <!--startprint-->
      <div class="text-center" style="margin-bottom:10px;font-size:20px;">
        <b>{{Bill.Title}}</b>
      </div>
      <div class="Foreword" v-html="Bill.Foreword"></div>
      <div class="ques-lst">
        <div :key="i" class="ques-item" v-for="(a, i) in Questions">
          <div>
            <b>{{a.QuesNumber}}、{{a.Title}}</b>
          </div>
          <ul class="choice-ul" v-if="a.TypeId==1||a.TypeId==2">
            <li :key="j" v-for="(b,j) in a.ChoiceItems">{{b.Letter}}、{{b.Content}}</li>
          </ul>
          <Input style="margin-top:10px;" :placeholder="a.Placeholder" type="textarea" v-if="a.TypeId==3" />
          <div style="padding-left:1em;margin-top:10px;" v-if="a.TypeId==4">
            <Icon type="md-star-outline" />
            <Icon type="md-star-outline" />
            <Icon type="md-star-outline" />
            <Icon type="md-star-outline" />
            <Icon type="md-star-outline" />
          </div>
        </div>
      </div>
      <div class="Postscript" v-html="Bill.Postscript" v-if="!!Bill.Postscript"></div>
      <!--endprint-->
      <!-- <div class="flex justify-center noprint" style="margin-top:10px" v-show="!loading">
        <Button @click="toPrint" type="primary">
          <Icon type="md-print" />打印
        </Button>
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      id: 0,
      OrderId: 0,
      UserId: 0,
      Bill: {},
      Questions: [],
      flag: true,
    };
  },
  mounted: function () {
    this.id = this.$route.query.id;
    this.req();
  },
  methods: {
    req() {
      this.loading = true;
      this.$axios
        .get("/Api/CmsSurvey/GetById2", {
          params: { Id: this.id },
        })
        .then((res) => {
          this.loading = false;
          if (res.result) {
            this.Bill = res.data.Bill;
            let ques = res.data.Questions;
            for (let i in ques) {
              if (ques[i].TypeId==3) {
                if (ques[i].ChoiceDetail) {
                  try {
                    ques[i].Placeholder = JSON.parse(ques[i].ChoiceDetail).Placeholder
                  } catch(err) {
                    console.log(err)
                  }
                }
              }
            }
            this.Questions = ques
          } else if (res.errormsg) {
            this.$Message.error(res.errormsg);
          }
        })
        .catch((err) => {
          this.loading = false;
          if (err.errormsg) {
            this.$Message.error(err.errormsg);
          }
        });
    },
    toPrint() {
      window.print();
    },
  },
};
</script>
<style lang="less">
#SurveyPreview {
  display: flex;
  justify-content: center;
  height: 100%;
  overflow: scroll;
  .main {
    width: 700px;
    position: relative;
    padding: 20px 0;
  }
  .Foreword {
    padding-top: 20px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e8eaec;
  }
  .Postscript {
    padding-top: 10px;
    margin-top: 10px;
    border-top: 1px solid #e8eaec;
  }
  .ques-item {
    margin-bottom: 10px;
  }
  .ques-item li {
    list-style: none;
  }
  .choice-ul {
    color: #515a6e;
    padding-left: 1em;
  }
}
@media print {
  .noprint {
    display: none;
  }
}
</style>